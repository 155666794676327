import React, {  useState, useEffect, createContext} from 'react';

import axios from 'axios';
import toast from 'react-hot-toast';
import CartData from './cartData';
import Loader from './loader';

import { link } from '../dynamic/api';

export const DataContext = createContext();
export const CartContext = createContext();

function GetData (props){

    const {setAccess, userEmail, token, expireyDate} = props;

    const api = axios.create({
      baseURL: link,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    const [loaderEffect, setLoaderEffect] = useState(true);

    const [categoryData, setCategoryData] = useState([]);
    const [refCategory, setRefCategory] = useState(false);

    const [salableData, setSalableData] = useState([]);
    const [refSalable, setRefSalable] = useState([]);

    const [productData, setProductData] = useState([]);
    const [refProduct, setRefProduct] = useState([]);
  
    const [accountData, setAccountData] = useState([]);
    const [refAccount, setRefAccount] = useState([]);

    const [wareHouseData, setWareHouseData] = useState([]);
    const [refWareHouse, setRefWareHouse] = useState([]);

    const [settingData, setSettingData] = useState(0);
    const [refSetting, setRefSetting] = useState(0);

    const [devSettingData, setDevSettingData] = useState(0);
    const [refDevSetting, setRefDevSetting] = useState(0);

    const [deliveryData, setDeliveryData] = useState([]);
    const [refDeliveryData, setRefDeliveryData] = useState([]);

    const [tablesData, setTablesData] = useState([]);
    const [refTablesData, setRefTablesData] = useState([]);

    const [roleData, setRoleData] = useState([]);
    const [refRoleData, setRefRoleData] = useState([]);

    const [printerData, setPrinterData] = useState([]);
    const [refPrinter, setRefPrinter] = useState([]);

    const [purchaseInvoiceData, setPurchaseInvoiceData] = useState([]);
    const [refPurchaseInvoiceData, setRefPurchaseInvoiceData] = useState([]);


    const [userData, setUserData] = useState('');

    const [allUserData, setAllUserData] = useState('');
    const [refAllUserData, setRefAllUseData] = useState([]);


    const refreshCategory = () => {
      setRefCategory(!refCategory)
    }

    const refreshAccount = () => {
      setRefAccount(!refAccount)
    }


    const refreshProduct = () => {
      setRefProduct(!refProduct)
    }

    const refreshSalable = () => {
      setRefSalable(!refSalable)
    }

    const refreshPrinter = () => {
      setRefPrinter(!refPrinter)
    }
    
    const refreshWareHouse = () => {
      setRefWareHouse(!refWareHouse)
    }

    const refreshTables = () => {
      setRefTablesData(!refTablesData)
    }

    const refreshSetting = () => {
      setRefSetting(!refSetting)
    }

    const refreshDelivery = () => {
      setRefDeliveryData(!refDeliveryData)
    }

    const refreshRole = () => {
      setRefRoleData(!refRoleData)
    }

    const refreshAllUserData = () => {
      setRefAllUseData(!refAllUserData)
    }

    const refreshPurchaseInvoiceData = () => {
      setRefPurchaseInvoiceData(!refPurchaseInvoiceData)
    }


    // Get Printer Data
    useEffect(() => {
      const fetchData = async () => {
        try {let data = { isActive: true };
          const response = await api.get(`/printer/getAll`, { params: data });
          if (response) {
            setPrinterData(response.data.data);
          }} catch (error) {
          toast.error('' + error);}};
      fetchData();
    }, [refPrinter]);

    // Get Transaction Data
    useEffect(() => {
      const fetchData = async () => {
        try {let data = { isActive: true };
          const response = await api.get(`/purchaseInvoice/getDropDown`, { params: data });
          if (response) {
            setPurchaseInvoiceData(response.data.data);
          }} catch (error) {
          toast.error('' + error);}};
      fetchData();
    }, [refPurchaseInvoiceData]);


    // Get Tables Data
    useEffect(() => {
      const fetchData = async () => {
        try {let data = { isActive: true };
          const response = await api.get(`/tables/getAll`, { params: data });
          if (response) {
            setTablesData(response.data.data);
          }} catch (error) {
          toast.error('' + error);}};
      fetchData();
    }, [refTablesData]);


      // Get DevSettings
      useEffect(() => {
        const fetchData = async () => {
          try {let data = { isActive: true };
            const response = await api.get(`/setting/getDev`, { params: data });
            if (response) {
              setDevSettingData(response.data.data);
            }} catch (error) {
            toast.error('' + error);}};
        fetchData();
      }, [refDevSetting]);


      // Get All User Data
      useEffect(() => {
        const fetchData = async () => {
          try {let data = { isActive: true };
            const response = await api.get(`/user/getAll`, { params: data });
            if (response) {
              setAllUserData(response.data.data);
            }} catch (error) {
            toast.error('' + error);}};
        fetchData();
      }, [refAllUserData]);


    // Get Role
    useEffect(() => {
      const fetchData = async () => {
        try {let data = { isActive: true };
          const response = await api.get(`/role/getAll`, { params: data });
          if (response) {
            setRoleData(response.data.data);
          }} catch (error) {
          toast.error('' + error);}};
      fetchData();
    }, [refRoleData]);


    // Get Category
    useEffect(() => {
      const fetchData = async () => {
        try {let data = { isActive: true };
          const response = await api.get(`/category/getAll`, { params: data });
          if (response) {
            setCategoryData(response.data.data);
          }} catch (error) {
          toast.error('' + error);}};
      fetchData();
    }, [refCategory]);


    // Get Delivery
    useEffect(() => {
      const fetchData = async () => {
        try {let data = { isActive: true };
          const response = await api.get(`/delivery/getAll`, { params: data });
          if (response) {
            setDeliveryData(response.data.data);
          }} catch (error) {
          toast.error('' + error);}};
     fetchData();
    }, [refDeliveryData]);


    // Get User Data
    useEffect(() => {
      const fetchData = async () => {
        try {let data = {email: userEmail };
          const response = await api.get(`/user/getUser`, { params: data });
          if (response) {
            setUserData(response.data.data);
          }} catch (error) {
          toast.error('' + error);}};
     fetchData();
    }, []);

    
    // Get Accounts
    useEffect(() => {
      const fetchData = async () => {
        try {let data = {isActive: true };
          const response = await api.get(`/account/getAll`, { params: data });
          if (response) {
            setAccountData(response.data.data);
          }} catch (error) {
          toast.error('' + error);}};
     fetchData();
    }, [refAccount]);



    // Get Product DropDown
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await api.get(`/Product/getDropDown`);
          if (response) {
            setProductData(response.data.data);
          }} catch (error) {
          toast.error('' + error);}};
     fetchData();
    }, [refProduct]);



    // Get wareHouses
    useEffect(() => {
      const fetchData = async () => {
        try {let data = {isActive: true };
          const response = await api.get(`/wareHouse/getAll`, { params: data });
          if (response) {
            setWareHouseData(response.data.data);
          }} catch (error) {
          toast.error('' + error);}};
     fetchData();
    }, [refWareHouse]);



    // Get Settings
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await api.get(`/Setting/getAll`);
          if (response) {
            setSettingData(response.data.data[0]);
          }} catch (error) {
          toast.error('' + error);}};
     fetchData();
    }, [refSetting]);
    


// Get Salable products Where wareHouse of Role
useEffect(() => {
  if(userData && settingData){   
    const fetchData = async () => { 
  let data = {
    isActive: true,
    isSale: true,
    wareHouseId: userData.role.wareHouseSaleId,

   };
  api.get(`/Product/getSalable`, { params: data }).then((results) => {
  if(results){
      setSalableData(results.data.data);
      toast.success('Products Refreshed Successfully')
  }}).catch((error) => {
  toast.error(''+error); });
  }
fetchData();
  }
}, [refSalable, userData, settingData]);





  return (
  <div>

      <DataContext.Provider 
      value={{
        setLoaderEffect,
        expireyDate,
        api,
        userData,
        categoryData,
        refreshCategory,
        accountData,
        refreshAccount,
        productData,
        refreshProduct,
        salableData,
        refreshSalable,
        wareHouseData,
        refreshWareHouse,
        settingData,
        refreshSetting, 
        deliveryData,
        refreshDelivery,
        roleData,
        refreshRole,
        setAccess,
        refreshAllUserData,
        allUserData,
        refreshPurchaseInvoiceData,
        purchaseInvoiceData,
        devSettingData,
        refDevSetting,
        tablesData,
        refreshTables,
        printerData,
        refreshPrinter,
        }}>


      {loaderEffect &&
        <Loader/>
        }
 

        {userData && allUserData &&
        <CartData setAccess={setAccess} settingData={settingData}/>
        }
    
  

    </DataContext.Provider>


    
    
  </div>
            
        );
    }

 
export default GetData;