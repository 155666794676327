import React, {  useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Route, Routes, useNavigate } from 'react-router-dom';

import './menu.css'
import toast from 'react-hot-toast';

import Loader from '../init/loader';


import { BiMenu, BiGridAlt, BiCartAlt, BiBox, BiGroup, BiArchiveIn,
   BiCog, BiLogOut, BiFile, BiBriefcase } from "react-icons/bi";

import { DataContext } from '../init/getData';

const DashboardLazy = React.lazy(() => import('../dashboard/dashboard'))
const ProductTabLazy = React.lazy(() => import('../productTab/productTab'))
const SettingTabLazy = React.lazy(() => import('../settings/settingTab'))

const AccountTabLazy = React.lazy(() => import('../accountTab/accountTab'))
const SalesLazy = React.lazy(() => import('../sales/sales'))
const TablesTabLazy = React.lazy(() => import('../tablesTab/tableTab'))
const OrderHeaderLazy = React.lazy(() => import('../order/orderHeader'))
const TransactionsTabLazy = React.lazy(() => import('../transactionsTab/transactionTab'))
const ReportTabLazy = React.lazy(() => import('../reportTab/reportTab'))


  function Menu (props){

      
    const {settingData, userData, setAccess, expireyDate, setLoaderEffect} = useContext(DataContext);


    const userId = userData.id;
    const userName = userData.name;
    const userRole = userData.role;

    const [toggle, setToggle] = useState('sidebar');
    const [tog, setTog] = useState(1);

    const navigate = useNavigate();

    useEffect(() => {
      setLoaderEffect(false)
    }, []);


    
     const toggles = () => {
      if(tog == 1){
        setTog(0);
        setToggle('sidebar open')
      }
      else {
        setTog(1);
        setToggle('sidebar')
      }
    }

    const signOut = () => {
      toast.success('Logged out');
      navigate('/');
      setAccess(false);
    }

  return (
<div>
  <div className={toggle}>
    <div className="logo-details">
      <i className='bx'></i>
        <div className="logo_name">RhinoPos</div>
        <i className='bx' id="btn" onClick={toggles}><BiMenu /></i>
    </div>
    <ul className="nav-list">

    {userRole.dashboardTab && 
      <li>
      <NavLink to="dashboard" className={({isActive}) => (isActive ? "active-style" : 'none')} >
          <i className='bx'><BiGridAlt /></i>
          <span className="links_name">Dashboard</span>
          </NavLink>
         <span className="tooltip">Dashboard</span>
      </li>
      }

    {userRole.salesTab && 
      <li>
        <NavLink to="sales" className={({isActive}) => (isActive ? "active-style" : 'none')} >
         <i className='bx' ><BiCartAlt/></i>
         <span className="links_name">Sales</span>
       </NavLink>
       <span className="tooltip">Sales</span>
     </li>
    }

    {userRole.deliveryTab && 
      <li>
        <NavLink to="tablesTab" className={({isActive}) => (isActive ? "active-style" : 'none')} >
         <i className='bx' ><BiCartAlt/></i>
         <span className="links_name">Tables</span>
       </NavLink>
       <span className="tooltip">Tables</span>
     </li>
      }

    {userRole.orderTab && 
    <li>
    <NavLink to="order" className={({isActive}) => (isActive ? "active-style" : 'none')}>
      <i className='bx' ><BiArchiveIn /></i>
        <span className="links_name">Orders</span>
        </NavLink>
      <span className="tooltip">Orders</span>
    </li>
    }

    {userRole.deliveryTab && 
    <li>
    <NavLink to="delivery" className={({isActive}) => (isActive ? "active-style" : 'none')}>
    <i className='bx' ><BiArchiveIn /></i>
        <span className="links_name">Delivery</span>
        </NavLink>
      <span className="tooltip">Delivery</span>
    </li> 
     }

  {userRole.productTab &&   
     <li>
     <NavLink to="productTab" className={({isActive}) => (isActive ? "active-style" : 'none')} >
         <i className='bx'><BiBox /></i>
         <span className="links_name">Products</span>
         </NavLink>
       <span className="tooltip">Products</span>
     </li>
    }

    {userRole.clientTab &&  
     <li>
     <NavLink to="accountTab" className={({isActive}) => (isActive ? "active-style" : 'none')}>
         <i className='bx'><BiGroup /></i>
         <span className="links_name">Accounts</span>
         </NavLink>
       <span className="tooltip">Accounts</span>
     </li>
  }

    {userRole.accountingTab && 
     <li>
     <NavLink to="transactionsTab" className={({isActive}) => (isActive ? "active-style" : 'none')}>
        <i className='bx'><BiBriefcase /></i>
         <span className="links_name">Transactions</span>
         </NavLink>
       <span className="tooltip">Transactions</span>
     </li>
    }

    {userRole.reportTab && 
     <li>
     <NavLink to="reportTab" className={({isActive}) => (isActive ? "active-style" : 'none')}>
        <i className='bx'><BiFile /></i>
         <span className="links_name">Reports</span>
         </NavLink>
       <span className="tooltip">Reports</span>
     </li>
    }


    {userRole.settingTab && 
     <li>
     <NavLink to="settingTab" className={({isActive}) => (isActive ? "active-style" : 'none')}>
          <i className='bx' ><BiCog /></i>
         <span className="links_name">Settings</span>
         </NavLink>
       <span className="tooltip">Settings</span>
     </li>
    }
     
     <li className="profile">
         <div className="profile-details">
           <div className="name_job">           
             <div className="name">User: {userName}</div>
             <div className="job">Role: {userRole.name}</div>
             
           </div>
         </div>
         <button onClick={signOut}>
         <i className='bx' id="log_out" ><BiLogOut /></i>
         </button>
     </li>
    </ul>
  </div>

  <section className="home-section">
  
  
  <Routes>
  <Route path='dashboard' element={ <React.Suspense fallback={<Loader/>}> <DashboardLazy/> </React.Suspense>}/>
  <Route path='productTab/*' element={ <React.Suspense fallback={<Loader/>}> <ProductTabLazy/> </React.Suspense>}/> 
  <Route path='accountTab/*'  element={ <React.Suspense fallback={<Loader/>}> <AccountTabLazy/> </React.Suspense>}/>
  <Route path='order'  element={ <React.Suspense fallback={<Loader/>}> <OrderHeaderLazy/> </React.Suspense>}/>
  <Route path='sales'  element={ <React.Suspense fallback={<Loader/>}> <SalesLazy/> </React.Suspense>}/>
  <Route path='tablesTab/*' element={ <React.Suspense fallback={<Loader/>}> <TablesTabLazy/> </React.Suspense>}/>
  <Route path='transactionsTab/*' element={ <React.Suspense fallback={<Loader/>}> <TransactionsTabLazy/> </React.Suspense>}/>
  <Route path='reportTab/*' element={ <React.Suspense fallback={<Loader/>}> <ReportTabLazy/> </React.Suspense>}/>
  <Route path='settingTab/*' element={ <React.Suspense fallback={<Loader/>}> <SettingTabLazy/> </React.Suspense>}/>
  </Routes>
  
  </section>

   </div>
            
        );
    }

 
export default Menu;