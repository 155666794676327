import axios from 'axios';
import Cookies from 'js-cookie';

const updateApi = () => {
  let url="http://localhost:5001/api";
  let token = Cookies.get('token');
  
  return axios.create({
      baseURL: url,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
};

let api = updateApi();

const link = 'https://thelightstorm.riserteam.com:5006/api';

export  { api, updateApi, link};